<template>
  <div>
    <div v-show="showInformations" class="wrapper">
      <div class="wrapper__card">
        <div class="wrapper__card__header">
          <div class="left">
            <span>Consultar opt-outs</span>
          </div>
          <div class="right">
            <div>
              <z-btn
                rounded
                primary
                text="adicionar"
                icon="mdi-plus"
                small
                @click="
                  resetAddModal();
                  dialogAdd = true;
                "
              />
            </div>
            <div>
              <z-btn
                rounded
                primary
                text="exportar"
                small
                @click="downloadCsv()"
              />
            </div>
            <div>
              <z-btn
                rounded
                primary
                text="baixar template"
                small
                href="/optouttemplate.csv"
              />
            </div>
            <div>
              <z-btn
                rounded
                primary
                text="importar"
                small
                @click="ImportCsv()"
              />
              <input
                @change="previewFiles"
                type="file"
                ref="file"
                accept=".csv"
                style="display: none"
              />
            </div>
          </div>
        </div>
        <v-row class="left">
          <div class="filterColumn">
            <z-input
              v-model="optoutFilter.searchText"
              placeholder="Pesquisar"
              prependIcon="$search_rounded"
              type="text"
              :hideDetails="true"
            />
          </div>
          <div class="filterColumn">
            <z-select
              v-model="optoutFilter.searchType"
              :hideDetails="true"
              placeholder="Tipo"
              :items="filterType"
              itemText="name"
              itemValue="value"
              class="select"
            />
          </div>
          <div class="filterColumn">
            <z-btn rounded primary text="buscar" @click="getOptOutList()" />
          </div>
        </v-row>
        <div class="wrapper__card__body">
          <!-- :number-of-items="filtered ? filtered.length : paginatedItems.length" -->
          <z-pagination
            :auto-pagination="false"
            icon-previous="mdi-chevron-left"
            icon-next="mdi-chevron-right"
            :items-per-page="10"
            :items="filtered ? filtered : paginatedItems"
            :number-of-pages="totalPages"
            :current-page="optoutFilter.page"
            @click:next="goToNextPage"
            @click:previous="backPreviousPage"
            @change:page="selectPage($event)"
          >
            <template #data="{ paginatedItems }">
              <div>
                <z-table
                  class="table"
                  :headers="headers"
                  fixed-header
                  height="600"
                  :items="paginatedItems"
                  icon-next="mdi-chevron-right"
                  icon-previous="mdi-chevron-left"
                  @sort="sortTable"
                >
                  <template #no-data>
                    <div class="no-data-table">
                      <h3>Sem dados disponíveis.</h3>
                    </div>
                  </template>

                  <template v-slot:[`item.type`]="{ item }">
                    <div class="d-flex">
                      <span>
                        {{ item.type ? item.type : "optout" }}
                      </span>
                    </div>
                  </template>

                  <template v-slot:[`item.created`]="{ item }">
                    <div class="d-flex">
                      <span>
                        {{ formateDateTime(item.created) }}
                      </span>
                    </div>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions">
                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-1"
                            @click="
                              deleteId = item._id;
                              dialogDelete = true;
                            "
                            >$delete_outline_rounded</v-icon
                          >
                        </template>
                        <span
                          style="
                            color: white;
                            padding: 0.5rem;
                            font-weight: 500;
                          "
                          >Apagar</span
                        >
                      </v-tooltip>
                    </div>
                  </template>
                </z-table>
              </div>
            </template>
          </z-pagination>
        </div>
      </div>
      <z-card-modal v-model="dialogDelete" width="400" title="">
        <template #content>
          <div class="delete-confirmation">
            <div class="delete-confirmation__title mb-5">
              <span>Tem certeza que deseja apagar esse opt-out?</span>
            </div>

            <div class="delete-confirmation--actions">
              <z-btn
                small
                rounded
                text="cancelar"
                @click="dialogDelete = false"
              />

              <z-btn
                rounded
                primary
                text="apagar"
                small
                :isLoading="loadingDelete"
                @click="confirmDelete()"
              />
            </div>
          </div>
        </template>
      </z-card-modal>

      <z-card-modal
        v-model="dialogAdd"
        width="400"
        title=""
        @click:outside="resetAddModal()"
      >
        <template #content>
          <v-form ref="formDialog" @submit.prevent>
            <div class="">
              <span class="mb-5">Insira os dados:</span>
              <z-input
                v-model="optOutAdd.number"
                class="mt-3"
                placeholder="Telefone"
                label="Telefone"
                :mask="'+## (##) #####-####'"
              />
              <z-input
                v-model="optOutAdd.email"
                label="Email"
                placeholder="Email"
                class="mt-3"
              />
            </div>

            <div class="delete-confirmation--actions">
              <z-btn small rounded text="cancelar" @click="resetAddModal()" />

              <z-btn
                rounded
                primary
                text="Adicionar"
                small
                :isLoading="loadingAdd"
                @click="addOptout()"
              />
            </div>
          </v-form>
        </template>
      </z-card-modal>
    </div>
    <div v-show="!showInformations" class="wrapper no-data">
      <div class="wrapper__card">
        <z-loading />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ApiClient from "@/services/ApiClient";
import Papa from "papaparse";

export default {
  data() {
    return {
      optoutFilter: {
        page: 1,
        searchText: "",
        searchType: "",
      },
      totalPages: 0,
      confirmed: false,
      http: new ApiClient(),
      optOutObj: {},
      showInformations: false,
      optOutAdd: {},
      loadingAdd: false,
      deleteId: null,
      types: ["phone", "email"],
      headers: [
        { text: "Telefone", value: "number", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Tipo", value: "type", sortable: false },
        { text: "Data", value: "created", sortable: false },
        { text: "Ações", value: "actions" },
      ],
      filtered: null,
      optOutAddEnabled: false,
      paginatedItems: [],
      loadingDelete: false,
      dialogDelete: false,
      dialogAdd: false,

      selectedCSVFile: null,
      sortDesc: false,
      filterType: [
        { name: "Tudo", value: "" },
        { name: "Opt Out", value: "optout" },
        { name: "Bounce", value: "bounce" },
      ],
    };
  },
  created() {
    this.getOptOutList();
  },
  watch: {
    dialogDelete(nv) {
      if (!nv) this.resetjourneyToDelete();
    },
  },
  methods: {
    resetOptAdd() {
      this.optOutAddEnabled = false;
      this.$forceUpdate();
      this.optOutAddEnabled = true;
    },
    searchOptOut() {
      this.optoutFilter.page = 1;
      this.getOptOutList();
    },
    formateDateTime(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    async sortTable(nameToSort) {
      this.sortDesc = !this.sortDesc;
      try {
        const ORDER = this.sortDesc ? "ASC" : "DESC";
        const response = await this.http.get(
          `optout/list?sort=${nameToSort}&order=${ORDER}`
        );
        if (response) {
          this.paginatedItems = response.items;
        }
      } catch (error) {
        this.$toast.error("Erro ao ordenar dados");
      }
    },
    async confirmDelete() {
      this.loadingDelete = true;
      try {
        await this.http.delete(`optout/` + this.deleteId);
        this.$toast.success("Opt-Outs deletado com sucesso!");
        this.getOptOutList();
        this.loadingDelete = false;
        this.dialogDelete = false;
      } catch {
        this.loadingDelete = false;
        this.$toast.error("Ocorreu um erro ao deletar Opt-Out!");
      }
    },
    goToNextPage() {
      this.optoutFilter.page += 1;
      this.getOptOutList();
    },

    backPreviousPage() {
      this.optoutFilter.page--;
      this.getOptOutList();
    },
    selectPage(page) {
      this.optoutFilter.page = page;
      this.getOptOutList();
    },
    async getOptOutList() {
      this.showInformations = false;
      try {
        const req = await this.http.get(`optOut/list`, this.optoutFilter);

        this.paginatedItems = req.items;
        // this.optoutFilter.page = +req.pages.toFixed(0) || 1;
        this.totalPages = req.pages;
        this.showInformations = true;
      } catch (error) {
        this.$toast.error("Erro ao listar opt-outs");
        this.showInformations = true;
      }
    },
    resetAddModal() {
      this.dialogAdd = false;
      this.loadingAdd = false;
      this.optOutAdd = {};
    },
    async downloadCsv() {
      try {
        const response = await this.http.get(`optOut/export`);

        const anchor = document.createElement("a");
        anchor.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(response);
        anchor.target = "_blank";
        anchor.download = "optout.csv";
        anchor.click();
        this.loadingDownload = false;
      } catch (error) {
        this.loadingDownload = false;
      }
    },
    async ImportCsv() {
      this.$refs.file.click();
    },
    Uint8ArrayFromBase64(base64) {
      return Uint8Array.from(window.atob(base64), (v) => v.charCodeAt(0));
    },
    async previewFiles(event) {
      const $context = this;
      this.selectedCSVFile = event.target.files[0];
      console.log(this.selectedCSVFile);

      const reader = new FileReader();
      reader.onload = () => {
        let csvData = reader.result;
        Papa.parse(csvData.replaceAll(";", ","), {
          header: true,
          complete: async function (results) {
            results.data = results.data.filter((x) => x.phone || x.email);
            console.log(results.data);

            await $context.http.post(`optOut/import`, results);
            // console.log(req);
            $context.$toast.success("Opt-Outs importados com sucesso!");
            $context.getOptOutList();
          },
        });
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(this.selectedCSVFile);
    },
    async addOptout() {
      if (this.$refs.formDialog.validate()) {
        try {
          this.loadingAdd = true;
          const req = await this.http.post(`optOut/add`, this.optOutAdd);

          if (req) {
            this.$toast.success("Opt-Out adicionado com sucesso!");
            this.resetAddModal();
            this.getOptOutList();
          } else {
            this.loadingAdd = false;
            this.$toast.error("Erro ao adicionar opt-out");
          }
        } catch (error) {
          this.loadingAdd = false;
          this.$toast.error("Erro ao adicionar opt-out");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.filterColumn {
  margin-right: 10px;
}

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: hidden;
    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;
        & > div {
        }

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }
    &__body {
      display: flex;
      flex-direction: column;
      gap: $z-s-1;
      height: 90%;
      .table-wrapper {
        height: 500px !important;
        max-height: 500px;
        overflow-y: auto;
        @include trackScrollBar;

        .no-data-table {
          text-align: center;
        }

        @media (min-width: 1550px) {
          height: auto;
          max-height: 650px;
        }
      }

      @media (min-width: 720px) and (max-width: 1079px) {
        .table-wrapper {
          height: 400px;
          max-height: 400px;
        }
      }

      @media (max-width: 890px) {
        height: auto;
        .table-wrapper {
          height: 350px;
          max-height: 350px;
        }
      }

      // // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          table {
            th {
              &:not(:first-child) {
                text-align: center !important;
              }
              &:last-child {
                text-align: right !important;
              }
            }
            td {
              &:not(:first-child) {
                text-align: center !important;
                & > div,
                span {
                  margin: 0 auto !important;
                }
              }
              &:last-child {
                text-align: right !important;
              }
            }
          }
        }
      }
    }
  }

  .badge {
    padding: 0.03rem 0.25rem;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    max-width: 58%;

    @media (max-width: 650px) {
      max-width: 100%;
    }
    &:hover {
      // background: rgb(236, 236, 236);
    }
  }
}

.list-options {
  background: #ffffff;
  &__item {
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.2s ease;
    span {
      font-weight: 500;
    }
    &:hover {
      background: #e2e2e2;
    }
  }
}

.delete-confirmation {
  padding: 1rem;
  &__title {
    text-align: center;
    font-weight: 500;
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
